<template>
  <b-container class="py-md-4">
    <div class="register_wrapper my-md-5 my-4 p-3">
      <b-row class="ml-5 mt-5">
        <b-col>
          <b-row>
            <h2
              class="
              font-weight-bold
              display-4
            "
            >
              Payment
            </h2>
          </b-row>
          <b-row
            :class="[style['total-payment'], 'font-weight-bold']"
          >
            <b-col
              md="9"
              class="h5 pt-4 pb-3 pl-5"
            >
              Total Payable Amount
            </b-col>
            <b-col class="h4 pt-4 pb-3">
              $116.00
            </b-col>
          </b-row>
          <b-row
            :class="['font-weight-bold']"
            style="width:83%"
          >
            <b-col
              :class="[style['radio-text'], 'h5 mt-2 border']"
            >
              <p class="mb-0 mt-4">
                <input
                  id="test1"
                  type="radio"
                  name="radio-group"
                  value="1"
                >
                <label
                  for="test1"
                  style="width:100%"
                >
                  <b-row>
                    <b-col lg="8"><p>Pay with credit card</p></b-col>
                    <b-col class="d-flex">
                      <div :class="[style['we-accept-text'],'mr-2 mt-1 text-center font-weight-light']">We accept</div> 
                      <img
                        src="../../assets/images/visa.png"
                        class="mr-3 mt-1 border-1"
                        style="width: 55px;height: 20px;"
                        fluid
                      > 
                      <img
                        src="../../assets/images/mastercard.png"
                        fluid
                        class="mt-1"
                        style="width: 60px;height:15px;"
                      >
                    </b-col>
                  </b-row>
                </label>
              </p>
            </b-col>
          </b-row>
          <b-row
            :class="['font-weight-bold']"
            style="width:83%"
          >
            <b-col
              :class="[style['radio-text'], 'h5 mt-2 border']"
            >
              <p class="mb-0 mt-4">
                <input
                  id="test2"
                  type="radio"
                  name="radio-group"
                  value="2"
                >
                <label
                  for="test2"
                  style="width:100%"
                >
                  <b-row>
                    <b-col md="3"><p>Pay with PayPal</p></b-col>
                    <b-col
                      md="5"
                      class="pl-0"
                    ><img
                      src="../../assets/images/paypall.png" 
                      fluid
                      class="mt-1"
                      style="width: 80px;height:20px;"
                    ></b-col>
                  </b-row>  
                </label>
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import style from '../../styles/shopping.module.scss';
export default {
    name:'Payment',
    data(){
        return {
            style,
            paymentType: [{ text: 'Pay with credit card', value: '2' },{text:'Pay with PayPal', value:'1'}],
            // paymentType2:[]
        };
    },
};
</script>